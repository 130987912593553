<template>
  <div class="d-flex flex-column" style="height: calc(100vh - var(--v-layout-top) - 32px)">
    <v-row v-if="percentComplete != null" class="flex-grow-0">
      <v-progress-linear
        v-model="percentComplete"
        color="blue-lighten-3"
        :max="1"
        height="25"
      >
        <template v-slot:default="{ value }">
          <span v-if="waitingStatus == 'InProgress'">
            <strong>{{ Math.ceil(value) }}% <span v-if="estimatedToCompleteAtDate > new Date(0)">({{ estimatedTimeRemaining }})</span></strong>
          </span>
          <span v-else-if="waitingStatus == 'Waiting'">
            <strong>Waiting...</strong>
          </span>
        </template>
      </v-progress-linear>
    </v-row>
    <v-row class="flex-grow-0">
      <v-col>
        <h1 class="text-sm-h3">Saved Schedules</h1>
      </v-col>
    </v-row>
    <v-row class="flex-shrink-1 flex-grow-1" style="min-height: 0;">
      <v-col cols="6" class="h-100" md="2">
        <v-card style="overflow: auto; max-height: 100%;">
          <v-list v-model:selected="selectedSchedules" mandatory>
            <v-list-item class="pa-0">
              <div class="d-flex justify-space-between pa-2">
                <v-btn :to="{name: 'studoku'}">
                  Create
                </v-btn>
                <v-btn
                  color="secondary"
                  @click="solve"
                >
                  Solve
                </v-btn>
              </div>
            </v-list-item>
            <v-list-item v-for="schedule in store.schedules" :key="schedule.id" :value="schedule">
              <v-list-item-title>{{ schedule.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="6" class="h-100" md="10">
        <v-card v-if="selectedSchedule" style="overflow: auto; max-height: 100%;">
          <v-container>
            <schedule-detail :schedule="selectedSchedule" :config="store.profile.availabilityConfig"/>
            <v-row>
              <v-col class="d-flex justify-end">
                <v-btn class="ms-2" color="error" @click="deleteSchedule(selectedSchedule!)">
                  Delete
                </v-btn>
                <v-btn class="ms-2" :disabled="store.profile.currentScheduleId === selectedSchedule.id"
                       @click="setAsCurrentSchedule(selectedSchedule!)">
                  Set as Current Schedule
                </v-btn>
                <v-btn class="ms-2" color="secondary"
                       :to="{name: 'studoku-edit', params: {id: selectedSchedule.id}, query: {dup: 'dup'}}">
                  Duplicate
                </v-btn>
                <v-btn class="ms-2" color="primary" :to="{name: 'studoku-edit', params: {id: selectedSchedule.id}}">
                  Edit in Studoku
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts" setup>
import {useAppStore} from "@/store";
import {Schedule, Status} from "contracts";
import {computed, onMounted, ref, watchEffect} from "vue";
import ScheduleDetail from "@/components/ScheduleDetail.vue";
import {useTimeAgo, useTimeoutPoll} from "@vueuse/core";
import {useRouter} from "vue-router";

const store = useAppStore();

const selectedSchedules = ref<Schedule[]>(store.schedules.length ? [store.schedules[0]] : []);
const selectedSchedule = computed<Schedule | null>({
  get(): Schedule | null {
    if (selectedSchedules.value.length === 1) {
      return selectedSchedules.value[0];
    } else {
      return null;
    }
  },
  set(value: Schedule | null) {
    if (value) {
      selectedSchedules.value = [value];
    } else {
      selectedSchedules.value = [];
    }
  }
});

function setAsCurrentSchedule(schedule: Schedule) {
  store.setAsCurrentSchedule(schedule);
}

function deleteSchedule(schedule: Schedule) {
  store.deleteSchedule(schedule);
  selectedSchedule.value = store.schedules.length ? store.schedules[0] : null;
}

const router = useRouter();

const percentComplete = ref<null | number>(null);
const estimatedToCompleteAtDate = ref<Date>(new Date());

const estimatedTimeRemaining = useTimeAgo<'second' | 'minute'>(estimatedToCompleteAtDate, {
  showSecond: true,
  updateInterval: 1000,
  messages: {
    justNow: 'any moment now',
    past: 'any moment now',
    future: n => n.match(/\d/) ? `in about ${n}` : n,
    minute: n => `${n} minute${n > 1 ? 's' : ''}`,
    second: n => `${n} second${n > 1 ? 's' : ''}`,
    invalid: 'in a little while',
  },
});

useTimeoutPoll(async () => {
  const status = await store.checkSolve();
  if (status) {
    percentComplete.value = status.percentComplete ?? null;
    estimatedToCompleteAtDate.value = status.estimatedToCompleteAtEpochMilli
      ? new Date(status.estimatedToCompleteAtEpochMilli)
      : new Date(0);
    waitingStatus.value = status.status;
    if (status.status === "Completed") {
      await saveSolution();
    }
  } else {
    percentComplete.value = null;
    estimatedToCompleteAtDate.value = new Date(0);
    waitingStatus.value = "Waiting";
  }
}, 1000, {
  immediate: true
});

const waitingStatus = ref<Status>("Waiting");

async function solve() {
  const newName = `Studoku'd at ${new Date().toLocaleString('en-US', {dateStyle: 'short', timeStyle: 'short'})}`;
  const solution = await store.trySaveSolution(newName);
  if (solution) {
    await router.push({name: 'studoku-edit', params: {id: solution.id}});
    store.snackbar = {text: 'Solution found', show: true, timeout: 3000};
  } else {
    await store.solve();
  }
}

async function saveSolution() {
  percentComplete.value = null;
  const newName = `Studoku'd at ${new Date().toLocaleString('en-US', {dateStyle: 'short', timeStyle: 'short'})}`;
  const newSchedule = await store.trySaveSolution(newName);
  if (newSchedule) {
    await router.push({name: 'studoku-edit', params: {id: newSchedule.id}});
    store.snackbar = {text: 'Solution found', show: true, timeout: 3000};
  } else {
    store.snackbar = {text: 'No solution found', show: true, timeout: 3000};
  }
}
</script>
