<template>
  <h1 class="text-sm-h3">Current Schedule</h1>
  <schedule-detail v-if="currentSchedule" :schedule="currentSchedule" :config="store.profile.availabilityConfig"/>
</template>

<script lang="ts" setup>
import {useAppStore} from "@/store";
import ScheduleDetail from "@/components/ScheduleDetail.vue";
import {computed} from "vue";

const store = useAppStore();
const currentSchedule = computed(() => store.schedules.find(s => s.id === store.profile.currentScheduleId))
</script>
