<template>
  <h1 class="text-sm-h3">Home</h1>
  <p>Here's how to get started:</p>
  <ol>
    <li>Reset your password in your
      <router-link to="/profile">
        <v-icon icon="mdi-account-outline"/>
        Profile
      </router-link>
    </li>
    <li>Set your
      <router-link to="/availability">
        <v-icon icon="mdi-calendar-check-outline"/>
        Teacher Availability
      </router-link>
    </li>
    <li>Add your students to the
      <router-link to="/directory">
        <v-icon icon="mdi-account-school-outline"/>
        Student Directory
      </router-link>
    </li>
    <li>Send out the
      <router-link to="/surveys">
        <v-icon icon="mdi-email-fast-outline"/>
        Preference Surveys
      </router-link>
    </li>
    <li>
      When everyone has replied, solve your schedule in
      <router-link to="/saved-schedules">
        <v-icon icon="mdi-clock-outline"/>
        Saved Schedules
      </router-link>
    </li>
    <li>
      When you have a schedule you like, you can set it as your
      <router-link to="/current-schedule">
        <v-icon icon="mdi-home-clock-outline"/>
        Current Schedule
      </router-link>
    </li>
  </ol>
</template>

<script lang="ts" setup>
</script>
