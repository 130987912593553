<template>
  <v-input :model-value="modelValue" class="time-picker">
    <v-col>
      <v-row>
        <v-label v-if="label">
          {{ label }}
        </v-label>
      </v-row>
      <v-row>
        <v-select
          :items="hours"
          v-model="hour"
          hide-details
        />
        <v-select
          :items="minutes"
          v-model="minute"
          hide-details
        />
        <v-select
          :items="amPms"
          v-model="amPm"
          hide-details
        />
      </v-row>
    </v-col>
  </v-input>
</template>
<script setup lang="ts">
import {computed} from "vue";
import {MinuteNumbers} from "luxon";
import {Hour12Numbers, hour12to24, hour24to12, Time} from "contracts";

const props = defineProps<{
  label?: string,
  modelValue: Time,
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: Time): void
}>();

const amPms = [
  {title: 'AM', value: 'am'},
  {title: 'PM', value: 'pm'},
];
const hours = computed(() => {
  const hours = [];
  for (let i = 1; i <= 12; i++) {
    hours.push({title: i.toString(), value: i});
  }
  return hours;
});
const minutes = computed(() => {
  const minutes = [];
  for (let i = 0; i < 60; i += 5) {
    minutes.push({title: i.toString(), value: i});
  }
  return minutes;
});

const hour = computed({
  get(): Hour12Numbers {
    return hour24to12(props.modelValue.hour);
  },
  set(value: Hour12Numbers) {
    const newTime = {...props.modelValue, hour: hour12to24(value, amPm.value)};
    emit('update:modelValue', newTime);
  },
});
const minute = computed({
  get(): MinuteNumbers {
    return props.modelValue.minute;
  },
  set(value: MinuteNumbers) {
    const newTime = {...props.modelValue, minute: value};
    emit('update:modelValue', newTime);
  },
});
const amPm = computed({
  get(): 'am' | 'pm' {
    if (props.modelValue.hour < 12) {
      return 'am';
    } else {
      return 'pm';
    }
  },
  set(value: 'am' | 'pm') {
    const newTime = {...props.modelValue, hour: hour12to24(hour.value, value)};
    emit('update:modelValue', newTime);
  },
});
</script>

<style scoped>
.time-picker {
  max-width: 16rem;
}
</style>
