<template>
  <v-row>
    <v-col cols="4">
      <v-text-field
        hide-details
        label="Name"
        v-model="editingSchedule.name"
      />
    </v-col>
    <v-col cols="8" class="d-flex align-center">
      <v-btn
        color="primary"
        @click="saveSchedule"
      >
        Save
      </v-btn>
    </v-col>
  </v-row>
  <v-row>
    <v-col class="studoku-puzzle" :style="{'--num-columns': tableColumns.length + 1}">
      <div></div>
      <v-label v-for="column in tableColumns" :key="column.value" class="column-header"
               :class="{'text-disabled': column.disabled, 'bg-grey-lighten-4': column.disabled}">
        {{ column.title }}
      </v-label>
      <template v-for="row in tableRows">
        <v-label class="row-header">
          {{ row.title }}
        </v-label>
        <div
          v-for="column in tableColumns"
          class="cell"
          :class="{'bg-grey-lighten-5': isUnavailable(column.value, row.value, config)}"
        >
          <template
            v-for="{student, selection} in (studentSelections.get(column.value)?.get(minuteInDay(row.value)) ?? [])"
          >
            <div
              :class="`text-${studentPreferenceColor(selection)}`"
              v-if="shouldShow(student, selection, row.value, column.value)">
              <!-- Rewrite the above in vanilla HTML -->
              <label
                class="checkbox-with-visual"
                :class="{disabled: isDisabled(student, column.value, row.value)}"
                :for="`${student.name}|${row.value.hour}|${row.value.minute}|${column.value}`"
              >
                <input
                  class="checkbox-input"
                  type="checkbox"
                  :disabled="isDisabled(student, column.value, row.value)"
                  :id="`${student.name}|${row.value.hour}|${row.value.minute}|${column.value}`"
                  :value="student.name"
                  :checked="studentIsSelectedAtTime(student, row.value, column.value)"
                  @change="selectStudentAtTime(($event.target as HTMLInputElement).checked, student, row.value, column.value)"
                >
                <span class="checkbox-visual">
                  <i class="checked mdi-checkbox-marked mdi v-icon notranslate v-theme--light v-icon--size-default" aria-hidden="true"></i>
                  <i class="unchecked mdi-checkbox-blank-outline mdi v-icon notranslate v-theme--light v-icon--size-default" aria-hidden="true"></i>
                </span>
                <span class="ms-1 checkbox-label">
                {{ student.name }}
                </span>
              </label>
            </div>
          </template>
        </div>
      </template>
    </v-col>
    <v-col cols="2">
      <v-card class="settings">
        <v-card-title>
          Show/Hide
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-checkbox label="Perfect" class="text-green colored-checkbox" v-model="showSelections" value="perfect"
                          hide-details/>
              <v-checkbox label="Works Well" class="text-yellow colored-checkbox" v-model="showSelections" value="works"
                          hide-details/>
              <v-checkbox label="Neutral" class="text-grey colored-checkbox" v-model="showSelections" value="neutral"
                          hide-details/>
              <v-checkbox label="Difficult" class="text-orange colored-checkbox" v-model="showSelections"
                          value="difficult" hide-details/>
              <v-checkbox label="Impossible" class="text-red colored-checkbox" v-model="showSelections"
                          value="impossible" hide-details/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script setup lang="ts">
import {
  AvailabilityConfig,
  availableTimes,
  isUnavailable,
  minuteInDay,
  Schedule,
  Student,
  StudentPreference,
  studentPreferenceColor,
  Time,
  timeFromMinuteInDay,
  Weekday
} from "contracts";
import {ref, watch} from "vue";
import {DateTime} from "luxon";
import {useAppStore} from "@/store";
import {cloneDeep} from "lodash-es";
import {useRouter} from "vue-router";

const store = useAppStore();

const router = useRouter();

const props = defineProps<{
  config: AvailabilityConfig,
  modelValue: Schedule,
}>();

type Column = { title: string, value: Weekday, disabled?: boolean }
type Row = { title: string, value: Time }

const tableColumns: Column[] = (() => {
  let columns: Column[] = [
    {title: 'Sun', value: 0},
    {title: 'Mon', value: 1},
    {title: 'Tue', value: 2},
    {title: 'Wed', value: 3},
    {title: 'Thu', value: 4},
    {title: 'Fri', value: 5},
    {title: 'Sat', value: 6},
  ];
  while (!!columns[0] && !props.config.preferences.some(it => it.weekday === columns[0].value && it.selection)) {
    columns.shift();
  }
  while (!!columns[columns.length - 1] && !props.config.preferences.some(it => it.weekday === columns[columns.length - 1].value && it.selection)) {
    columns.pop();
  }
  columns = columns.map(it => {
    if (props.config.preferences.some(it2 => it2.weekday === it.value && it2.selection)) {
      return it;
    } else {
      return {...it, disabled: true};
    }
  });
  return columns;
})();

const tableRows: Row[] = (() => {
  const times = availableTimes(props.config);
  return times.map(it => ({
    title: DateTime.fromObject(it).toFormat('h:mma').toLowerCase(),
    value: {hour: it.hour, minute: it.minute}
  }));
})();

const editingSchedule = ref<Schedule>(cloneDeep(props.modelValue));
watch(props, () => {
  editingSchedule.value = cloneDeep(props.modelValue);
});
const emit = defineEmits<{
  (event: 'update:modelValue', value: Schedule): void
}>();

function saveSchedule() {
  emit('update:modelValue', cloneDeep(editingSchedule.value));
}

function studentIsSelected(student: Student): boolean {
  return editingSchedule.value.slots.some(it => it.student.id === student.id);
}

function studentIsSelectedAtTime(student: Student, time: Time, weekday: Weekday): boolean {
  const selectedStudent = findSelectedStudent(time, weekday);
  return !!selectedStudent && selectedStudent.id === student.id;
}

function findSelectedStudent(time: Time, weekday: Weekday): Student | undefined {
  return editingSchedule.value.slots.find(it => {
    const minute = minuteInDay(time);
    const duration = it.student.settings.lessonMinutes;
    const start = minuteInDay(it.time);
    const end = start + duration;
    return it.weekday === weekday && (start <= minute && minute < end);
  })?.student;
}

function selectStudentAtTime(state: boolean, student: Student, time: Time, weekday: Weekday) {
  if (state) {
    editingSchedule.value.slots.push({student, time, weekday});
  } else {
    editingSchedule.value.slots = editingSchedule.value.slots.filter(it => it.student.id !== student.id);
  }
}

function isDisabled(student: Student, weekday: Weekday, startTime: Time) {
  if (studentIsSelectedAtTime(student, startTime, weekday)) {
    return false;
  }
  const startMinute = minuteInDay(startTime);
  for (let i = 0; i < student.settings.lessonMinutes; i += props.config.timeIncrement) {
    const time = timeFromMinuteInDay(startMinute + i);
    if (isUnavailable(weekday, time, props.config)) {
      return true;
    }
    if (findSelectedStudent(time, weekday) !== undefined) {
      return true;
    }
  }
  return false;
}

function shouldShow(student: Student, selection: StudentPreference, time: Time, weekday: Weekday): boolean {
  const selectedStudent = findSelectedStudent(time, weekday);
  if (isUnavailable(weekday, time, props.config) && selectedStudent?.id !== student.id) {
    return false;
  }
  if (studentIsSelected(student) && selectedStudent?.id !== student.id) {
    return false;
  }
  if (selectedStudent === undefined && showSelections.value.includes(selection)) {
    return true;
  }
  return selectedStudent?.id === student.id;
}

const studentSelections = store.studentSelections();
const showSelections = ref(["perfect", "works", "neutral"])
</script>

<style scoped>
.studoku-puzzle {
  --num-columns: 1;
  display: grid;
  grid-template-columns: 85px repeat(calc(var(--num-columns) - 1), 1fr);
  padding-left: 0;
  padding-right: 0;
  cursor: default;
  user-select: none;
}

.studoku-puzzle > * {
  padding-left: 8px;
  padding-right: 8px;
}

.column-header {
  font-weight: bold;
}

.row-header {
  font-weight: bold;
  text-align: right;
  padding: 8px;
  align-items: start;
  line-height: 40px;
  justify-content: end;
}

.cell {
  padding: 8px;
}

.colored-checkbox :deep(.v-icon) {
  opacity: 1 !important;
}

.colored-checkbox :deep(.v-label) {
  color: black !important;
}

.checkbox-with-visual {
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.checkbox-with-visual .checkbox-input {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.checkbox-with-visual.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.checkbox-with-visual * {
  cursor: inherit;
}

.checkbox-with-visual .checkbox-visual i {
  vertical-align: top;
}

.checkbox-with-visual .checkbox-input:not(:checked) + .checkbox-visual > .checked {
  display: none;
}

.checkbox-with-visual .checkbox-input:checked + .checkbox-visual > .unchecked {
  display: none;
}

.checkbox-with-visual .checkbox-input:checked + .checkbox-visual > .checked {
  display: inline-block;
}

.checkbox-with-visual .checkbox-input:not(:checked) + .checkbox-visual > .unchecked {
  display: inline-block;
}
</style>
