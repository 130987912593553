<template>
  <v-col>
    <v-row>
      <h1 class="text-sm-h3">Teacher Availability</h1>
    </v-row>
    <v-row>
      <v-card max-width="30rem">
        <v-container>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-select
              v-model="config.timeIncrement"
              label="Time Increments"
              :items="timeIncrements"
            />
            <v-select
              label="Lesson Durations"
              :items="lessonDurations"
              multiple
              v-model="config.lessonDurations"
            />
            <time-picker label="Start Time" v-model="config.startTime"/>
            <time-picker :rules="endTimeRules" label="End Time" v-model="config.endTime"/>
            <v-btn
              color="primary"
              @click="save"
            >
              Save
            </v-btn>
          </v-form>
        </v-container>
      </v-card>
      <v-card>
        <v-container>
          <v-radio-group inline v-model="selectionType">
            <palette-radio color="green" label="Available" :value="true"/>
            <palette-radio color="grey" label="Unavailable" :value="false"/>
          </v-radio-group>
          <schedule-survey
            :config="config"
            v-model="config.preferences"
            :selection-type="selectionType"
            :brush-size="1"
            :selection-to-color="(bool) => bool ? 'green' : 'grey'"
          />
        </v-container>
      </v-card>
    </v-row>
  </v-col>
</template>

<script lang="ts" setup>
import {useAppStore} from "@/store";
import {AvailabilityConfig, Time} from "contracts";
import TimePicker from "@/components/TimePicker.vue";
import {ref, watch} from "vue";
import {VForm} from "vuetify/components";
import ScheduleSurvey from "@/components/ScheduleSurvey.vue";
import PaletteRadio from "@/components/PaletteRadio.vue";
import {cloneDeep} from "lodash-es";

const form = ref<VForm>()
const store = useAppStore();
const config = ref<AvailabilityConfig>(cloneDeep(store.profile.availabilityConfig));

const timeIncrements = [
  {title: '15 Minutes', value: 15},
  {title: '30 Minutes', value: 30},
  {title: '1 Hour', value: 60},
];

const lessonDurations = [
  {value: 30, title: '30 minutes'},
  {value: 45, title: '45 minutes'},
  {value: 60, title: '60 minutes'},
  {value: 75, title: '75 minutes'},
  {value: 90, title: '90 minutes'},
  {value: 105, title: '105 minutes'},
  {value: 120, title: '120 minutes'},
];

const endTimeRules = [
  (v: Time) => !!v || 'End time is required',
  (v: Time) => (v.hour > config.value.startTime.hour || v.hour == config.value.startTime.hour && v.minute > config.value.startTime.minute)
    || 'End time must be after start time',
];

watch(config, (_) => {
  form.value?.validate();
  config.value.lessonDurations = config.value.lessonDurations.sort((a, b) => a - b);
}, {deep: true});

const selectionType = ref(true);

async function save() {
  if (await form.value?.validate()) {
    await store.saveAvailabilityConfig(config.value);
  }
}
</script>
