<template>
  <div class="schedule-survey" :style="{'--num-columns': tableColumns.length}">
    <div v-for="column in tableColumns" :key="column.value" class="column-header"
         :class="{'text-disabled': column.disabled, 'bg-grey-lighten-4': column.disabled}">
      {{ column.title }}
    </div>
    <template v-for="(row, i) in tableRows">
      <div
        v-for="(column, j) in tableColumns"
        :data-row="i"
        :data-col="j"
        :key="`${row.title}--${column.title}`"
        class="cell"
        :class="cellClasses(row, column)"
        @mousedown="mouseDown(row, column)"
        @mouseenter="mouseEnter(row, column)"
        @mouseleave="mouseLeave(row, column)"
      >
        {{ row.title }}
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
import {
  AvailabilityConfig,
  Column,
  isUnavailable,
  minuteInDay,
  Preference,
  Reference,
  Row,
  scheduleCols,
  scheduleRows,
  selectionOf,
  simplifyPreferences,
  timeFromMinuteInDay,
  weekdaysBetween
} from "contracts";
import {computed, onMounted, onUnmounted, ref} from "vue";
import {cloneDeep} from "lodash-es";

const props = defineProps<{
  config: AvailabilityConfig,
  modelValue: Preference<any>[],
  selectionType: any,
  selectionToColor: (_: any) => string,
  disabled?: boolean,
  brushSize: number,
  lockToAvailability?: boolean,
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: Preference<any>[]): void
}>();

const tableColumns = computed<Column[]>(() => {
  return scheduleCols(props.config, props.lockToAvailability);
});

const tableRows = computed<Row[]>(() => {
  return scheduleRows(props.config);
});

const startReference = ref<Reference | null>(null);
const lastReference = ref<Reference | null>(null);

const pendingWeekdays = computed(() => {
  const start = startReference.value;
  const end = lastReference.value;
  if (start && end) {
    return weekdaysBetween(start.column.value, end.column.value)
  } else {
    return [];
  }
});

function cellClasses(row: Row, column: Column): object | string[] {
  if (!!props.lockToAvailability && isUnavailable(column.value, row.value, props.config)) {
    return ['text-disabled', 'bg-grey-lighten-4']
  } else if (isHovered(row, column)) {
    const color = props.selectionToColor(props.selectionType);
    return [`bg-${color}`];
  } else {
    const color = props.selectionToColor(isPending(row, column) ? props.selectionType : selectionOf(column.value, row.value, props.config.timeIncrement, props.modelValue));
    return [`bg-${color}`];
  }
}

function isPending(row: Row, column: Column): boolean {
  let start = startReference.value;
  let end = lastReference.value;
  if (start && end) {
    if (minuteInDay(start.row.value) > minuteInDay(end.row.value)) {
      [start, end] = [end, start];
    }
    const currentEndIndex = tableRows.value.findIndex(it => it.title === end!!.row.title);
    const fullEndIndex = Math.min(tableRows.value.length - 1, currentEndIndex + props.brushSize - 1);
    end = {row: tableRows.value[fullEndIndex], column: end.column};
    return pendingWeekdays.value.includes(column.value)
      && minuteInDay(row.value) >= minuteInDay(start.row.value)
      && minuteInDay(row.value) <= minuteInDay(end.row.value);
  } else {
    return false;
  }
}

function mouseDown(row: Row, column: Column) {
  if (!props.disabled) {
    startReference.value = {row, column};
    lastReference.value = {row, column};
  }
}

const hoveredReferences = ref<Reference[]>([]);

function isHovered(row: Row, column: Column): boolean {
  return hoveredReferences.value.some(it => it.row.title === row.title && it.column.title === column.title);
}

function mouseEnter(row: Row, column: Column) {
  if (startReference.value) {
    lastReference.value = {row, column};
  }
  if (!props.disabled) {
    hoveredReferences.value = [];
    let startIndex = tableRows.value.findIndex(it => it.title === row.title);
    let endIndex = startIndex + props.brushSize;
    hoveredReferences.value = tableRows.value.slice(startIndex, endIndex).map(it => ({row: it, column}));
  }
}

function mouseLeave(_row: Row, _column: Column) {
  if (!props.disabled) {
    hoveredReferences.value = [];
  }
}


const previousEl = ref<Element | null>(null);

function mouseUp() {
  let start = cloneDeep(startReference.value);
  let end = cloneDeep(lastReference.value);
  if (start && end) {
    if (minuteInDay(start.row.value) > minuteInDay(end.row.value)) {
      [start, end] = [end, start];
    }
    const currentEndIndex = tableRows.value.findIndex(it => it.title === end!!.row.title);
    const fullEndIndex = Math.min(tableRows.value.length - 1, currentEndIndex + props.brushSize - 1);
    end = {row: tableRows.value[fullEndIndex], column: end.column};
    const newPreferences: Preference<any>[] = [];
    for (const weekday of pendingWeekdays.value) {
      newPreferences.push({
        weekday,
        startTime: start.row.value,
        endTime: timeFromMinuteInDay(minuteInDay(end.row.value) + props.config.timeIncrement),
        selection: props.selectionType,
      });
    }
    emit('update:modelValue', simplifyPreferences([...newPreferences, ...cloneDeep(props.modelValue)], props.config, props.lockToAvailability))
  }
  startReference.value = null;
  lastReference.value = null;
}

// Listen for global mouse up
onMounted(() => window.addEventListener('mouseup', mouseUp));
onUnmounted(() => window.removeEventListener('mouseup', mouseUp));

function touchHandler(event: any) {
  const first = (event.changedTouches)[0];
  const curr = document.elementFromPoint(first.clientX, first.clientY);
  if (!curr || !curr.classList.contains('cell')) return;
  const currRowIndex = parseInt(curr?.getAttribute('data-row') ?? '-1');
  const currColIndex = parseInt(curr?.getAttribute('data-col') ?? '-1');
  const currRow = tableRows.value[currRowIndex];
  const currCol = tableColumns.value[currColIndex];

  const prev = previousEl.value;

  if (event.type === 'touchstart') {
    mouseDown(currRow, currCol);
  } else if (event.type === 'touchend') {
    mouseUp();
  } else {
    if (prev !== curr) {
      mouseEnter(currRow, currCol);
      previousEl.value = curr;
      if (prev) {
        const prevRowIndex = parseInt(prev.getAttribute('data-row') ?? '-1');
        const prevColIndex = parseInt(prev.getAttribute('data-col') ?? '-1');
        const prevRow = tableRows.value[prevRowIndex];
        const prevCol = tableColumns.value[prevColIndex];
        mouseLeave(prevRow, prevCol);
      }
    }
  }
  event.preventDefault();
  return false;
}

onMounted(() => {
  document.addEventListener('touchstart', touchHandler, {passive: false});
  document.addEventListener('touchmove', touchHandler, {passive: false});
  document.addEventListener('touchend', touchHandler, {passive: false});
});

onUnmounted(() => {
  document.removeEventListener('touchstart', touchHandler);
  document.removeEventListener('touchmove', touchHandler);
  document.removeEventListener('touchend', touchHandler);
});
</script>

<style scoped>
.schedule-survey {
  --num-columns: 1;
  display: grid;
  grid-template-columns: repeat(var(--num-columns), 1fr);
  cursor: default;
  -webkit-user-select: none; /* Safari */
  user-select: none;
}

.schedule-survey > * {
  padding-left: 8px;
  padding-right: 8px;
}

.column-header {
  font-weight: bold;
}

.cell {
  padding: 4px 8px;
}

@media screen and (max-width: 600px) {
  .cell {
    font-size: 0.9rem;
    padding: 2px 4px;
  }
}
</style>
