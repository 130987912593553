<template>
  <h1 class="text-sm-h3">Student Directory</h1>
  <v-table>
    <thead>
    <tr>
      <th>Name</th>
      <th>Lesson Minutes</th>
      <th>Email</th>
      <th>Edit</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="student in students" :key="student.id">
      <td>{{ student.name }}</td>
      <td>{{ student.settings.lessonMinutes }}</td>
      <td>{{ student.email }}</td>
      <td>
        <v-btn @click.stop="editStudent(student)" icon="mdi-pencil-outline"/>
      </td>
    </tr>
    </tbody>
  </v-table>
  <v-btn @click="addStudent">Add Student</v-btn>
  <v-dialog v-model="editDialog">
    <v-card max-width="40rem" class="ml-auto mr-auto w-100">
      <v-card-title v-if="editingOrAdding == 'editing'">Edit Student</v-card-title>
      <v-card-title v-if="editingOrAdding == 'adding'">Add Student</v-card-title>
      <v-card-text v-if="editingStudent">
        <v-form
          ref="form"
          lazy-validation
        >
          <v-text-field v-model="editingStudent.name" label="Name*" :rules="nameRules" autofocus/>
          <v-text-field v-model="editingStudent.email" label="Email*" :rules="emailRules"/>
          <v-select
            v-model="editingStudent.settings.lessonMinutes"
            :items="store.profile.availabilityConfig.lessonDurations.map(d => ({value: d, title: `${d} minutes`}))"
            label="Lesson Minutes"
          />
          <v-text-field v-model="editingStudent.notes" label="Notes"/>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="secondary" @click="editDialog = false">Cancel</v-btn>
        <v-btn color="error" v-if="editingOrAdding == 'editing'" @click="confirmDialog = true">Delete</v-btn>
        <v-spacer/>
        <v-btn color="secondary" v-if="editingOrAdding == 'adding'" @click="saveAndAddAnother">Save & Add Another
        </v-btn>
        <v-btn color="primary" @click="saveStudent">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="confirmDialog">
    <v-card max-width="40rem" class="ml-auto mr-auto w-100">
      <v-card-title>Delete Student</v-card-title>
      <v-card-text>
        Are you sure you want to delete {{ editingStudent?.name }}?
      </v-card-text>
      <v-card-actions>
        <v-btn color="secondary" @click="confirmDialog = false">Cancel</v-btn>
        <v-spacer/>
        <v-btn color="error" @click="deleteStudent">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import {useAppStore} from "@/store";
import {Student} from "contracts";
import {computed, ref} from "vue";
import {cloneDeep} from "lodash-es";
import {ulid} from "ulid";
import {VForm} from "vuetify/components/VForm";
import {VTextField} from "vuetify/components/VTextField";

const store = useAppStore();
const students = computed(() => store.students);

const form = ref<VForm>();
const nameInput = ref<VTextField>();
const editingStudent = ref<Student | null>(null);
const editDialog = ref(false);
const editingOrAdding = ref<'editing' | 'adding'>('editing');

const nameRules = [
  (v: string) => !!v || "Name is required",
];
const emailRules = [
  (v: string) => !!v || "Email is required",
  (v: string) => /.+@.+\..+/.test(v) || "Email must be valid",
];

function addStudent() {
  editingStudent.value = {
    id: ulid(),
    name: "",
    email: "",
    notes: "",
    settings: {
      lessonMinutes: store.profile.availabilityConfig.lessonDurations[0],
      preferences: [],
      lastUpdated: null,
    },
    token: null
  };
  editDialog.value = true;
  editingOrAdding.value = 'adding';
  form.value?.resetValidation();
}

function editStudent(student: Student) {
  editingStudent.value = cloneDeep(student);
  editDialog.value = true;
  editingOrAdding.value = 'editing';
  form.value?.resetValidation();
}

async function saveStudent() {
  const {valid} = await form.value?.validate() ?? {valid: false};
  if (!valid) {
    return;
  }
  const student = editingStudent.value;
  if (student) {
    await store.saveStudent(student);
    editingStudent.value = null;
    editDialog.value = false;
  }
}

const confirmDialog = ref(false);

async function deleteStudent() {
  const student = editingStudent.value;
  if (student) {
    await store.deleteStudent(student);
    editingStudent.value = null;
    editDialog.value = false;
    confirmDialog.value = false;
  }
}

async function saveAndAddAnother() {
  await saveStudent();
  addStudent();
}
</script>
