<template>
  <h1 class="text-sm-h3">
    Surveys
    <v-btn @click="sendPreferenceSurveys()" icon="mdi-send-outline"/>
  </h1>
  <v-row>
    <v-col>
      <v-list v-model:selected="selectedStudents" mandatory>
        <v-list-item v-for="student in students" :key="student.id" :value="student">
          <v-list-item-title>{{ student.name }}</v-list-item-title>
          <v-list-item-subtitle v-if="student.settings.lastUpdated">
            Last updated {{ formatDateTime(student.settings.lastUpdated) }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else>
            Never updated
          </v-list-item-subtitle>
          <template v-slot:append>
            <v-btn @click="sendPreferenceSurvey(student)" icon="mdi-send" :color="sendSurveyButtonColor(student)"/>
          </template>
        </v-list-item>
      </v-list>
    </v-col>
    <v-col>
      <v-card v-if="selectedStudent">
        <v-container>
          <v-select
            v-if="!!editingPreferences"
            class="mt-1"
            hide-details
            v-model="selectedStudent.settings!.lessonMinutes"
            :items="store.profile.availabilityConfig!.lessonDurations.map(d => ({value: d, title: `${d} minutes`}))"
            label="Lesson Minutes"
          />
          <v-radio-group inline v-model="selectionType" v-if="!!editingPreferences">
            <palette-radio color="green" label="Perfect" value="perfect"/>
            <palette-radio color="yellow" label="Works Well" value="works"/>
            <palette-radio color="grey" label="Neutral" value="neutral"/>
            <palette-radio color="orange" label="Difficult" value="difficult"/>
            <palette-radio color="red" label="Impossible" value="impossible"/>
          </v-radio-group>
          <schedule-survey
            v-if="preferences"
            :config="store.profile.availabilityConfig"
            :disabled="!editingPreferences"
            v-model="preferences"
            :selection-type="selectionType"
            :selection-to-color="studentPreferenceColor"
            lock-to-availability
            :brush-size="Math.round(selectedStudent.settings.lessonMinutes / store.profile.availabilityConfig.timeIncrement)"
          />
          <v-btn v-if="!editingPreferences" @click="editPreferences">Edit</v-btn>
          <v-btn v-if="editingPreferences" @click="savePreferences">Save</v-btn>
        </v-container>
      </v-card>
    </v-col>
    <!-- Date select modal -->
    <v-dialog
      v-model="showDateModal"
      max-width="290px"
    >
      <v-card>
        <v-card-title class="headline">Select a due date</v-card-title>
        <v-card-text>
          <v-text-field
            type="date"
            v-model="selectedDate"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="secondary"
            text
            @click="showDateModal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="dateSelectResolve(selectedDate)"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script lang="ts" setup>
import {useAppStore} from "@/store";
import {computed, ref, watch} from "vue";
import PaletteRadio from "@/components/PaletteRadio.vue";
import ScheduleSurvey from "@/components/ScheduleSurvey.vue";
import {Preference, Student, StudentPreference, studentPreferenceColor} from "contracts";
import {cloneDeep} from "lodash-es";
import {DateTime} from "luxon";

const store = useAppStore();
const students = computed(() => store.students);
const selectionType = ref<StudentPreference>('perfect');
const selectedStudents = ref<Student[]>(store.students.length > 0 ? [store.students[0]] : []);
const editingPreferences = ref<Preference<StudentPreference>[] | null>(null);

const showDateModal = ref(false);
const dateSelectResolve = ref<((value: string) => void)>(() => {
});
const selectedDate = ref<string>(DateTime.now().plus({week: 1}).toISODate()!);

const selectedStudent = computed<Student | null>({
  get(): Student | null {
    if (selectedStudents.value.length === 1) {
      return selectedStudents.value[0];
    } else {
      return null;
    }
  },
  set(value: Student | null) {
    if (value) {
      selectedStudents.value = [value];
    } else {
      selectedStudents.value = [];
    }
  }
});

const preferences = computed<Preference<StudentPreference>[] | null>({
  get(): Preference<StudentPreference>[] | null {
    const editing = editingPreferences.value;
    const selected = selectedStudent.value;
    if (editing) {
      return editing;
    } else if (selected) {
      return selected.settings.preferences;
    } else {
      return null;
    }
  },
  set(value: Preference<StudentPreference>[] | null) {
    if (editingPreferences.value && value) {
      editingPreferences.value = value;
    }
  }
});

function editPreferences() {
  if (preferences.value) {
    editingPreferences.value = cloneDeep(preferences.value);
  }
}

function formatDateTime(date: Date): string {
  return date.toLocaleString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
}

async function savePreferences() {
  const editing = editingPreferences.value;
  if (editing && selectedStudents.value.length === 1) {
    selectedStudents.value[0].settings.preferences = editing;
    selectedStudents.value[0].settings.lastUpdated = new Date();
    const studentId = selectedStudents.value[0].id;
    await store.saveStudent(selectedStudents.value[0]);
    selectedStudents.value = [store.students.find(s => s.id === studentId)!];
  }
  editingPreferences.value = null;
}

async function promptForDate(): Promise<Date> {
  const stringDate = await new Promise<string>(resolve => {
    dateSelectResolve.value = resolve;
    showDateModal.value = true;
  })
  // TODO: Hard-coded to America/Denver for now
  return DateTime.fromISO(stringDate).setZone('America/Denver').endOf('day').toJSDate();
}

function sendSurveyButtonColor(student: Student) {
  if (!student.token || student.token.dueByDate < new Date()) {
    return 'grey';
  }
  if (student.settings.lastUpdated && student.settings.lastUpdated > student.token.createdAt) {
    return 'green';
  } else {
    return 'orange';
  }
}

async function sendPreferenceSurvey(student: Student) {
  const dueByDate = await promptForDate();
  await store.sendPreferenceSurvey(student, dueByDate);
  showDateModal.value = false;
  dateSelectResolve.value = () => {
  };
  await store.getStudents();
}

async function sendPreferenceSurveys() {
  const dueByDate = await promptForDate();
  await store.sendPreferenceSurveys(dueByDate);
  showDateModal.value = false;
  dateSelectResolve.value = () => {
  };
  await store.getStudents();
}

watch(selectedStudent, () => {
  editingPreferences.value = null;
})
</script>
