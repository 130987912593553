<template>
  <v-card max-width="40rem">
    <v-container>
      <h1 class="text-sm-h3">Profile</h1>
      <v-list v-if="!editingProfile">
        <v-list-item>
          <v-list-item-subtitle>Name</v-list-item-subtitle>
          <v-list-item-title>{{ store.profile.name }}</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-subtitle>Email</v-list-item-subtitle>
          <v-list-item-title>{{ store.profile.email }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-form
        ref="profileForm"
        lazy-validation
        v-else
      >
        <v-text-field
          v-model="editingProfile.name"
          label="Name*"
          :rules="nameRules"
        />
        <v-text-field
          v-model="editingProfile.email"
          label="Email*"
          :rules="emailRules"
        />
      </v-form>
      <v-btn v-if="!editingProfile" @click="editProfile">Edit</v-btn>
      <v-btn v-else @click="saveProfile">Save</v-btn>
      <v-divider class="mt-2 mb-2"/>
      <v-form
        ref="passwordForm"
        lazy-validation
        v-if="changingPassword"
      >
        <v-text-field
          v-model="changingPassword.currentPassword"
          label="Old Password*"
          :rules="currentPasswordRules"
          type="password"
          @input="currentPasswordMatches = null"
        />
        <v-text-field
          v-model="changingPassword.newPassword"
          label="New Password*"
          :rules="newPasswordRules"
          type="password"
        />
        <v-text-field
          v-model="changingPassword.newPasswordConfirm"
          label="Confirm Password*"
          :rules="newPasswordConfirmRules"
          type="password"
        />
      </v-form>
      <v-btn v-if="!changingPassword" @click="changePassword">Change Password</v-btn>
      <v-btn v-else @click="savePassword">Save</v-btn>
      <v-alert
        v-if="error"
        type="error"
        dismissible
        elevation="2"
        class="mt-4"
      >
        {{ error }}
      </v-alert>
    </v-container>
  </v-card>
</template>

<script lang="ts" setup>
import {ref} from "vue";
import {VForm} from "vuetify/components/VForm";
import {PasswordChange, Profile} from "contracts";
import {useAppStore} from "@/store";
import {cloneDeep} from "lodash-es";
import {HttpError} from "@/errors";

const store = useAppStore();

const profileForm = ref<VForm>();
const passwordForm = ref<VForm>();
const editingProfile = ref<Profile | null>(null);
const changingPassword = ref<PasswordChange | null>(null);
const error = ref<string | null>(null);

const nameRules = [
  (v: string) => !!v || "Name is required",
];
const emailRules = [
  (v: string) => !!v || "Email is required",
  (v: string) => /.+@.+\..+/.test(v) || "Email must be valid",
];

function editProfile() {
  error.value = null;
  editingProfile.value = cloneDeep(store.profile);
  profileForm.value?.resetValidation();
}

async function saveProfile() {
  error.value = null;
  const {valid} = await profileForm.value?.validate() ?? {valid: false};
  if (!valid) {
    return;
  }
  const profile = editingProfile.value;
  if (profile) {
    try {
      await store.saveProfile(profile);
      editingProfile.value = null;
    } catch (e) {
      if (e instanceof HttpError && e.body.error) {
        error.value = e.body.error;
      } else {
        error.value = "An unknown error occurred.";
      }
    }
  }
}

const currentPasswordMatches = ref<boolean | null>();

const currentPasswordRules = [
  (v: string) => !!v || "Current password is required",
  (_: string) => currentPasswordMatches.value !== false || "Password is incorrect",
];

const newPasswordRules = [
  (v: string) => !!v || "New password is required",
  (v: string) => v.length >= 8 || "Password must be at least 8 characters",
];

const newPasswordConfirmRules = [
  (v: string) => !!v || "Confirm password is required",
  (v: string) => v === changingPassword.value?.newPassword || "Passwords must match",
];

function changePassword() {
  error.value = null;
  changingPassword.value = {
    currentPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  };
  passwordForm.value?.resetValidation();
}

async function savePassword() {
  error.value = null;
  const {valid} = await passwordForm.value?.validate() ?? {valid: false};
  if (!valid) {
    return;
  }
  const passwordChange = changingPassword.value;
  if (passwordChange) {
    try {
      const result = await store.savePassword(passwordChange);
      if (result) {
        changingPassword.value = null;
      } else {
        currentPasswordMatches.value = false;
        await passwordForm.value?.validate();
      }
    } catch (e) {
      if (e instanceof HttpError && e.body.error) {
        error.value = e.body.error;
      } else {
        error.value = "An unknown error occurred.";
      }
    }
  }
}
</script>
