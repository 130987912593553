<template>
  <v-container class="align-start justify-center d-flex w-100">
    <v-card class="survey-card pa-4 pa-md-8" max-width="40rem">
      <v-form v-if="availability && settings">
        <h1 class="text-h5">{{ name }}'s Preferences</h1>
        <v-select
          class="mt-1"
          hide-details
          v-model="settings!.lessonMinutes"
          :items="availability!.lessonDurations.map(d => ({value: d, title: `${d} minutes`}))"
          label="Lesson Minutes"
        />
        <v-radio-group inline v-model="selectionType" hide-details>
          <palette-radio color="green" label="Perfect" value="perfect"/>
          <palette-radio color="yellow" label="Works Well" value="works"/>
          <palette-radio color="grey" label="Neutral" value="neutral"/>
          <palette-radio color="orange" label="Difficult" value="difficult"/>
          <palette-radio color="red" label="Impossible" value="impossible"/>
        </v-radio-group>
        <schedule-survey
          :config="availability!"
          v-model="settings!.preferences"
          :selection-type="selectionType"
          :selection-to-color="studentPreferenceColor"
          lock-to-availability
          :brush-size="Math.round(settings!.lessonMinutes / availability!.timeIncrement)"
        />
        <div class="mt-2 d-flex justify-space-between align-center" style="gap: 8px;">
          <div class="text-grey text-body-2">
            Last updated {{ settings?.lastUpdated ? formatDateTime(settings!.lastUpdated) : 'never' }}
          </div>
          <v-btn
            color="primary"
            @click="saveSettings"
          >
            Save Changes
          </v-btn>
        </div>
      </v-form>
      <div v-else-if="unauthorized">
        <h1 class="text-h5">Link Expired</h1>
        <p>
          This link has expired. Please contact your teacher to get a new link.
        </p>
      </div>
      <div v-else>
        <v-progress-circular indeterminate/>
      </div>
    </v-card>
    <v-snackbar
      v-model="snackbar.show"
      v-bind="{timeout: snackbar.timeout}"
    >
      {{ snackbar.text }}

      <template v-slot:actions>
        <v-btn
          color="blue"
          variant="text"
          @click="snackbar.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script lang="ts" setup>
import {computed, ref, watchEffect} from "vue";
import {useRouter} from "vue-router";
import {convertTypes} from "@/store";
import {AvailabilityConfig, StudentPreference, studentPreferenceColor, StudentSettings, TokenReadInfo} from "contracts";
import PaletteRadio from "@/components/PaletteRadio.vue";
import ScheduleSurvey from "@/components/ScheduleSurvey.vue";
import {HttpError, throwError} from "@/errors";

const router = useRouter();
const unauthorized = ref(false);
const tokenId = computed(() => router.currentRoute.value.params.tokenId);
const settings = ref<StudentSettings | null>(null);
const availability = ref<AvailabilityConfig | null>(null);
const name = ref<string | null>(null);
const selectionType = ref<StudentPreference>('perfect');

async function fetchData() {
  if (tokenId.value) {
    try {
      const result = await fetchGet<TokenReadInfo>(`/api/student/read-only?token=${tokenId.value}`);
      settings.value = result.settings;
      availability.value = result.availabilityConfig;
      name.value = result.name;
    } catch (e) {
      if (e instanceof HttpError && e.status === 403) {
        unauthorized.value = true;
      } else {
        throw e;
      }
    }
  }
}

// Look up settings using tokenId
watchEffect(fetchData);

async function saveSettings() {
  if (settings.value) {
    await fetchPost(`/api/student/settings?token=${tokenId.value}`, settings.value);
    await fetchData();
  }
}

async function fetchPost<R>(url: string, body: any): Promise<R> {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    await throwError(response);
  }
  snackbar.value = {
    show: true,
    text: 'Preferences saved',
    timeout: 3000,
  };
  return convertTypes(await response.json());
}

async function fetchGet<R>(url: string): Promise<R> {
  const response = await fetch(url);
  if (!response.ok) {
    await throwError(response);
  }
  return convertTypes(await response.json());
}

const snackbar = ref({
  show: false,
  text: '',
  timeout: 3000,
});

function formatDateTime(date: Date): string {
  return date.toLocaleString('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
}

</script>
