import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Home from "@/views/Home.vue";
import Profile from "@/views/Profile.vue";
import Availability from "@/views/Availability.vue";
import Directory from "@/views/Directory.vue";
import Surveys from "@/views/Surveys.vue";
import CurrentSchedule from "@/views/CurrentSchedule.vue";
import SavedSchedules from "@/views/SavedSchedules.vue";
import Studoku from "@/views/Studoku.vue";
import Login from "@/views/Login.vue";
import {useAppStore} from "@/store";
import StudentSurvey from "@/views/StudentSurvey.vue";

const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: '/',
    component: () => import('@/layouts/Default.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'index',
        redirect: {path: 'home'},
      },
      {
        path: 'current-schedule',
        name: 'current-schedule',
        component: CurrentSchedule,
      },
      {
        path: 'saved-schedules',
        name: 'saved-schedules',
        component: SavedSchedules,
      },
      {
        path: 'studoku',
        name: 'studoku',
        component: Studoku,
      },
      {
        path: 'studoku/:id',
        name: 'studoku-edit',
        component: Studoku,
      },
      {
        path: 'surveys',
        name: 'surveys',
        component: Surveys,
      },
      {
        path: 'directory',
        name: 'directory',
        component: Directory,
      },
      {
        path: 'availability',
        name: 'availability',
        component: Availability,
      },
      {
        path: 'home',
        name: 'home',
        component: Home,
      },
      {
        path: 'profile',
        name: 'profile',
        component: Profile,
      }
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/survey/:tokenId',
    name: 'student-survey',
    component: StudentSurvey,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const store = useAppStore();
    if (!store.authenticated) {
      try {
        await store.checkAuth();
        next();
      } catch (_) {
        next({name: 'login', query: {redirect: to.fullPath}})
      }
    } else {
      next()
    }
  } else {
    next()
  }
});

export default router
