<template>
  <v-container class="align-center d-flex h-100 w-100 position-fixed">
    <v-row class="justify-center">
      <v-card max-width="40rem" min-width="30rem">
        <v-container>
          <h1 class="text-sm-h3">Login</h1>
          <v-form @submit.prevent="submit($event)">
            <v-text-field
              name="username"
              id="username"
              v-model="username"
              label="Username*"
              type="text"
              autocomplete="username"
            />
            <v-text-field
              name="password"
              id="password"
              v-model="password"
              label="Password*"
              type="password"
              autocomplete="current-password"
            />
            <v-btn type="submit">Login</v-btn>
            <v-alert
              v-if="error"
              type="error"
              dismissible
              elevation="2"
              class="mt-4"
            >
              {{ error }}
            </v-alert>
          </v-form>
        </v-container>
      </v-card>
    </v-row>
  </v-container>
</template>

<script lang="ts" setup>
import {useAppStore} from "@/store";
import {onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import {SubmitEventPromise} from "vuetify";
import {HttpError} from "@/errors";

const store = useAppStore();
const router = useRouter();

const username = ref("");
const password = ref("");
const error = ref<string | null>(null);

async function submit(_e: SubmitEventPromise) {
  try {
    await store.login({username: username.value, password: password.value});
    await redirect();
    return true;
  } catch (e) {
    if (e instanceof HttpError && e.body.error) {
      error.value = e.body.error;
    } else {
      error.value = "An unknown error occurred.";
    }
    return false;
  }
}

async function redirect() {
  const redirect = router.currentRoute.value.query['redirect'];
  if (redirect) {
    await router.push(redirect.toString());
  } else {
    await router.push("/");
  }
}

onMounted(async () => {
  await store.checkAuth();
  if (store.authenticated) {
    await redirect();
  }
});
</script>
