<template>
  <v-row>
    <v-col>
      <h2 class="text-sm-h4">{{ schedule.name }}</h2>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <table class="schedule-detail">
        <thead>
        <tr>
          <th class="column-1"></th>
          <th v-for="column in tableColumns" :key="column.value">
            <v-label :class="{'text-disabled': column.disabled, 'bg-grey-lighten-4': column.disabled}">
              {{ column.title }}
            </v-label>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in tableRows">
          <th class="row-header">
            <v-label>
              {{ row.title }}
            </v-label>
          </th>
          <template v-for="column in tableColumns">
            <td
              class="bg-blue-lighten-5"
              v-if="isStartOfLesson(row.value, column.value)"
              :rowspan="Math.ceil((findSelectedSlot(row.value, column.value)?.student?.settings.lessonMinutes ?? 0) / config.timeIncrement)"
              :key="`${row.title}--${column.title}`"
            >
              <v-label>
                {{ findSelectedSlot(row.value, column.value)?.student?.name }}
              </v-label>
            </td>
            <td
              v-else-if="!findSelectedSlot(row.value, column.value)"
              :key="`${row.title}--${column.title}`"
            >
            </td>
          </template>
        </tr>
        </tbody>
      </table>
    </v-col>
  </v-row>
</template>
<script setup lang="ts">
import {AvailabilityConfig, availableTimes, minuteInDay, Schedule, ScheduleSlot, Time, Weekday} from "contracts";
import {computed} from "vue";
import {DateTime} from "luxon";

const props = defineProps<{
  config: AvailabilityConfig,
  schedule: Schedule,
}>();

type Column = { title: string, value: Weekday, disabled?: boolean }
type Row = { title: string, value: Time }

const tableColumns = computed<Column[]>(() => {
  let columns: Column[] = [
    {title: 'Sun', value: 0},
    {title: 'Mon', value: 1},
    {title: 'Tue', value: 2},
    {title: 'Wed', value: 3},
    {title: 'Thu', value: 4},
    {title: 'Fri', value: 5},
    {title: 'Sat', value: 6},
  ];
  while (!!columns[0] && !props.config.preferences.some(it => it.weekday === columns[0].value && it.selection)) {
    columns.shift();
  }
  while (!!columns[columns.length - 1] && !props.config.preferences.some(it => it.weekday === columns[columns.length - 1].value && it.selection)) {
    columns.pop();
  }
  columns = columns.map(it => {
    if (props.config.preferences.some(it2 => it2.weekday === it.value && it2.selection)) {
      return it;
    } else {
      return {...it, disabled: true};
    }
  });
  return columns;
});

const tableRows = computed<Row[]>(() => {
  const times = availableTimes(props.config);
  return times.map(it => ({
    title: DateTime.fromObject(it).toFormat('h:mma').toLowerCase(),
    value: {hour: it.hour, minute: it.minute}
  }));
});

function findSelectedSlot(time: Time, weekday: Weekday): ScheduleSlot | undefined {
  return props.schedule.slots.find(it => {
    const minute = minuteInDay(time);
    const duration = it.student.settings.lessonMinutes;
    const start = minuteInDay(it.time);
    const end = start + duration;
    return it.weekday === weekday && (start <= minute && minute < end);
  });
}

function isStartOfLesson(time: Time, weekday: Weekday): boolean {
  const slot = findSelectedSlot(time, weekday);
  if (!slot) {
    return false;
  }
  return minuteInDay(slot.time) === minuteInDay(time);
}
</script>

<style scoped>
.schedule-detail {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}

.schedule-detail thead th {
  text-align: left;
  width: 10rem;
}

.schedule-detail thead th.column-1 {
  width: 5rem;
}

.schedule-detail tbody th {
  text-align: right;
  padding-right: 1rem;
}

.schedule-detail td, .schedule-detail th {
  border: 1px solid #ddd;
  vertical-align: top;
  padding: 4px;
}

.schedule-detail td[rowspan] {
  box-shadow: inset 0 0 0 1px #aaa;
  z-index: 1;
  position: relative;
}

</style>
