export class HttpError {
  constructor(public body: any, public status: number, public statusText: string) {
  }
}

export async function throwError(response: Response) {
  let body;
  try {
    body = await response.json();
  } catch (e) {
    const text = await response.text();
    if (text) {
      body = text;
    } else {
      body = '';
    }
  }
  throw new HttpError(body, response.status, response.statusText);
}
