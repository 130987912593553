import {defineStore} from 'pinia'
import {
  AvailabilityConfig,
  Credentials,
  minuteInDay,
  PasswordChange,
  Profile,
  Schedule,
  selectionOf, SolveStatus,
  Student,
  StudentPreference,
  timeFromMinuteInDay,
  Weekday
} from "contracts";
import {throwError} from "@/errors";

interface AppState {
  authenticated: boolean,
  snackbar: { text: string, show: boolean, timeout?: number | undefined },
  profile: Profile,
  students: Student[],
  schedules: Schedule[],
}

export const useAppStore = defineStore('app', {
  state: (): AppState => ({
    authenticated: false,
    snackbar: {text: '', show: false},
    profile: {
      id: '',
      name: 'none',
      email: 'none',
      currentScheduleId: null,
      availabilityConfig: {
        timeIncrement: 30,
        lessonDurations: [30, 45, 60],
        startTime: {hour: 13, minute: 0},
        endTime: {hour: 18, minute: 0},
        preferences: [
          {weekday: 0, startTime: {hour: 13, minute: 0}, endTime: {hour: 18, minute: 0}, selection: false},
          {weekday: 1, startTime: {hour: 13, minute: 0}, endTime: {hour: 18, minute: 0}, selection: true},
          {weekday: 2, startTime: {hour: 13, minute: 0}, endTime: {hour: 18, minute: 0}, selection: true},
          {weekday: 3, startTime: {hour: 13, minute: 0}, endTime: {hour: 18, minute: 0}, selection: true},
          {weekday: 4, startTime: {hour: 13, minute: 0}, endTime: {hour: 18, minute: 0}, selection: true},
          {weekday: 5, startTime: {hour: 13, minute: 0}, endTime: {hour: 18, minute: 0}, selection: true},
          {weekday: 6, startTime: {hour: 13, minute: 0}, endTime: {hour: 18, minute: 0}, selection: false},
        ],
      }
    },
    students: [],
    schedules: [],
  }),
  actions: {
    async init() {
      await this.getProfile();
      await this.getStudents();
      await this.getSchedules();
    },
    async login(credentials: Credentials) {
      await this.fetchPost('/api/login', credentials);
      this.authenticated = true;
      await this.init();
    },
    async checkAuth() {
      await this.fetchGet('/api/check-auth');
      this.authenticated = true;
      await this.init();
    },
    async getProfile() {
      this.profile = await this.fetchGet('/api/profile');
    },
    async saveProfile(profile: Profile) {
      this.profile = await this.fetchPost('/api/profile', profile);
      this.snackbar = {text: 'Profile saved', show: true, timeout: 3000};
    },
    async savePassword({currentPassword, newPassword}: PasswordChange): Promise<boolean> {
      await this.fetchPost('/api/change-password', {currentPassword, newPassword});
      this.snackbar = {text: 'Password saved', show: true, timeout: 3000};
      return true
    },
    async saveAvailabilityConfig(availabilityConfig: AvailabilityConfig) {
      await this.saveProfile({...this.profile, availabilityConfig});
      this.snackbar = {text: 'Availability config saved', show: true, timeout: 3000};
    },
    async getStudents() {
      this.students = await this.fetchGet('/api/student');
    },
    async saveStudent(student: Student): Promise<Student> {
      const response = await this.fetchPost<Student>('/api/student', student);
      await this.getStudents();
      this.snackbar = {text: `Student saved: ${student.name}`, show: true, timeout: 3000};
      return response;
    },
    async deleteStudent(student: Student): Promise<Student> {
      const response = await this.fetchDelete<Student>('/api/student/' + student.id);
      await this.getStudents();
      this.snackbar = {text: `Student deleted: ${student.name}`, show: true, timeout: 3000};
      return response;
    },
    async sendPreferenceSurvey(student: Student, dueByDate: Date) {
      await this.fetchPost('/api/student/token', {studentId: student.id, dueByDate});
      this.snackbar = {text: `Preference survey sent to ${student.name}`, show: true, timeout: 3000};
    },
    async sendPreferenceSurveys(dueByDate: Date) {
      await this.fetchPost('/api/student/token/all', {dueByDate});
      this.snackbar = {text: 'Preference surveys sent to all students', show: true, timeout: 3000};
    },
    async getSchedules() {
      this.schedules = await this.fetchGet('/api/schedule');
    },
    async saveSchedule(schedule: Schedule): Promise<Schedule> {
      schedule = await this.fetchPost('/api/schedule', schedule);
      this.schedules = [...this.schedules.filter(s => s.id !== schedule.id), schedule];
      this.snackbar = {text: 'Schedule saved', show: true, timeout: 3000};
      return schedule;
    },
    async setAsCurrentSchedule(schedule: Schedule) {
      await this.saveProfile({...this.profile, currentScheduleId: schedule.id})
      this.snackbar = {text: 'Schedule set as current', show: true, timeout: 3000};
    },
    async deleteSchedule(schedule: Schedule) {
      await this.fetchDelete('/api/schedule/' + schedule.id);
      this.schedules = this.schedules.filter(s => s.id !== schedule.id);
      this.snackbar = {text: 'Schedule deleted', show: true, timeout: 3000};
    },
    async solve() {
      await this.fetchPost('/api/solve', {scheduleId: this.profile.currentScheduleId});
      this.snackbar = {text: 'Solution enqueued', show: true, timeout: 3000};
    },
    async checkSolve() {
      try {
        return await this.fetchGet<SolveStatus>('/api/solve');
      } catch (e) {
        return null;
      }
    },
    async trySaveSolution(name: string) {
      try {
        const schedule = await this.fetchPost<Schedule>('/api/solve/save', {name});
        this.schedules = [...this.schedules.filter(s => s.id !== schedule.id), schedule];
        return schedule;
      } catch (e) {
        return null;
      }
    },
    async fetchPost<R>(url: string, body: any): Promise<R> {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      if (!response.ok) {
        if (response.status === 401) {
          this.authenticated = false;
        }
        await throwError(response);
      }
      return convertTypes(await response.json());
    },
    async fetchGet<R>(url: string): Promise<R> {
      const response = await fetch(url);
      if (!response.ok) {
        if (response.status === 401) {
          this.authenticated = false;
        }
        await throwError(response);
      }
      return convertTypes(await response.json());
    },
    async fetchDelete<R>(url: string): Promise<R> {
      const response = await fetch(url, {
        method: 'DELETE',
      });
      if (!response.ok) {
        if (response.status === 401) {
          this.authenticated = false;
        }
        await throwError(response);
      }
      return convertTypes(await response.json());
    },
    studentSelections(): Map<Weekday, Map<number, { student: Student, selection: StudentPreference }[]>> {
      const preferences = new Map<Weekday, Map<number, { student: Student, selection: StudentPreference }[]>>()
      // Iterate over weekdays
      for (const weekday of [0, 1, 2, 3, 4, 5, 6] as Weekday[]) {
        if (!preferences.has(weekday)) {
          preferences.set(weekday, new Map<number, { student: Student, selection: StudentPreference }[]>())
        }
        let minute = minuteInDay(this.profile.availabilityConfig.startTime);
        while (minute < minuteInDay(this.profile.availabilityConfig.endTime)) {
          const time = timeFromMinuteInDay(minute);
          const preferencesAtTime: { student: Student, selection: StudentPreference }[] = [];
          for (const student of this.students) {
            const selection = selectionOf(weekday, time, this.profile.availabilityConfig.timeIncrement, student.settings.preferences) ?? 'neutral';
            preferencesAtTime.push({student: student, selection});
          }
          preferences.get(weekday)!.set(minute, preferencesAtTime);
          minute += this.profile.availabilityConfig.timeIncrement;
        }
      }
      return preferences;
    }
  }
})

const ISO_8601_DATETIME_REGEX: RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/;

export function convertTypes<T extends Record<string, any>>(o: T): T {
  if (typeof o !== "object" || o === null) return o;
  for (const key of Object.keys(o) as (keyof T)[]) {
    const value = o[key];
    // noinspection SuspiciousTypeOfGuard
    if (typeof value === 'string' && ISO_8601_DATETIME_REGEX.test(value)) {
      o[key] = new Date(value) as any;
    } else if (typeof value === 'object') {
      o[key] = convertTypes(value);
    }
  }
  return o;
}
