<template>
  <studoku-puzzle v-if="schedule" :config="store.profile.availabilityConfig" v-model="schedule"
                  @update:modelValue="saveSchedule"/>
</template>

<script lang="ts" setup>
import {useAppStore} from "@/store";
import {Schedule, Student} from "contracts";
import {computed, ref, watch} from "vue";
import StudokuPuzzle from "@/components/StudokuPuzzle.vue";
import {useRoute, useRouter} from "vue-router";
import {ulid} from "ulid";
import {cloneDeep} from "lodash-es";

const store = useAppStore();
const route = useRoute();
const router = useRouter();
const scheduleId = computed(() => route.params.id);
const schedule = ref<Schedule>();

function removeMissingStudents(schedule: Schedule, students: Student[]): Schedule {
  const studentIds = students.map(s => s.id);
  const slots = schedule.slots.filter(slot => studentIds.includes(slot.student.id));
  return {...schedule, slots};
}

async function saveSchedule(schedule: Schedule) {
  const savedSchedule = await store.saveSchedule(schedule);
  if (savedSchedule.id !== scheduleId.value) {
    await router.push({name: 'studoku-edit', params: {id: savedSchedule.id}});
  }
}

watch(scheduleId, () => {
  if (scheduleId.value) {
    const found = store.schedules.find(s => s.id === scheduleId.value);
    if (found) {
      const cleaned = removeMissingStudents(found, store.students);
      if (route.query.dup) {
        schedule.value = {...cloneDeep(cleaned), id: ulid(), name: `Copy of ${cleaned.name}`};
      } else {
        schedule.value = cleaned;
      }
    } else {
      throw new Error(`Schedule ${scheduleId.value} not found`);
    }
  } else {
    schedule.value = {
      id: ulid(),
      name: '',
      slots: [],
    }
  }
}, {immediate: true})
</script>
